/** @jsxImportSource theme-ui */

import QueryString from 'qs'
import { createContext } from 'react'

import moment from 'moment'

import groupBy from 'lodash/groupBy'

import { dateRangeOptions } from 'src/app/containers/Reporting/utils'

export const STATUS_NODE_TYPES = [
  'USER',
  'RESOURCE',
  'LOCATION',
  'ASSET'
]

export const StatusValueModalContext = createContext()

export function getGroupStatus(
  groupIsCritical,
  groupNeedsAttention,
  groupHasOneStatus
) {
  return groupIsCritical
    ? 'Critical'
    : groupNeedsAttention
    ? 'Attention'
    : groupHasOneStatus
    ? 'OK'
    : 'N/A'
}

export function getGroupStatusColor(
  groupIsCritical,
  groupNeedsAttention,
  groupExpisReset,
  groupHasOneStatus
) {
  return groupIsCritical
    ? groupIsCritical?.statusValue?.color || 'danger'
    : groupNeedsAttention
    ? groupNeedsAttention?.statusValue?.color || 'yellow'
    : groupExpisReset?.statusValue?.color === 'RESET'
    ? 'secondary'
    : groupHasOneStatus
    ? 'success'
    : 'secondary'
}

export function getExpGroupStatus(
  groupIsCritical,
  groupNeedsAttention,
  groupExpisReset,
  groupHasOneStatus
) {
  return groupIsCritical
    ? 'Critical'
    : groupNeedsAttention
    ? 'Attention'
    : groupExpisReset
    ? 'seconadry'
    : groupHasOneStatus
    ? 'OK'
    : 'N/A'
}

export function getExpGroupStatusColor(
  groupIsCritical,
  groupNeedsAttention,
  groupExpisReset,
  groupHasOneStatus
) {
  return groupIsCritical
    ? groupIsCritical?.statusValue?.expirationColor || 'danger'
    : groupNeedsAttention
    ? groupNeedsAttention?.statusValue?.expirationColor || 'yellow'
    : groupExpisReset?.statusValue?.expirationColor === 'RESET'
    ? 'secondary'
    : groupHasOneStatus
    ? 'success'
    : 'secondary'
}

export const statusFilterJSONToURL = json => {
  const params = QueryString.stringify(json)

  const type = json.nodeType?.toLowerCase()

  return `/agency/pulse/${type}?${params}`
}

export const statusFilterURLToJSON = url => {
  const params = new URLSearchParams(url)
  return Object.fromEntries(params.entries())
}

export function formatDashboardFilters(filters) {
  return {
    nodeType: filters.nodeType,
    statusTemplates: pluckIdAndName(filters.statusTemplates),
    statusTemplateCategories: pluckIdAndName(
      filters.statusTemplateCategories
    ),
    tableView: filters.tableView,
    focusMode: filters.focusMode,
    date: filters.date,
    groups: pluckIdAndName(filters.groups),
    search: filters.search,
    expandAll: filters.expandAll,
    allActive: filters.allActive
  }
}

function pluckIdAndName(arr) {
  if (!arr) return null

  return arr.map(a => ({
    id: a.id,
    name: a.name,
    title: a.title
  }))
}

export function filterStatusTemplatesByCategoryForNode(
  statusTemplates,
  node,
  currentStatuses
) {
  return statusTemplates
    .filter(st => {
      if (!st.onlyForGroups) return true

      if (st.onlyForGroups.length === 0) return true

      return st?.onlyForGroups?.some(
        tag =>
          node?.category?.id === tag.id ||
          node?.tags?.some(t => t.id === tag.id)
      )
    })
    .map(st => ({
      ...st,
      statusValue: currentStatuses
        ?.find(d => d.id === st?.id)
        ?.statusValues.find(s => s?.relationId === node.id)
    }))
}

export const groupStatusTemplatesByCategory = statustemplates => {
  return Object.entries(groupBy(statustemplates, 'category.id')).map(
    ([key, templates]) => ({
      key,
      category: templates[0].category,
      templates: templates
    })
  )
}

export function groupStatusTemplatesByNodeandCategory(
  statusTemplatesForThisNode
) {
  return groupStatusTemplatesByCategory(statusTemplatesForThisNode)
    .map(({ key, templates }) => {
      const groupIsCritical = templates.find(
        template => template.statusValue?.alertLevel === 'CRITICAL'
      )
      const groupNeedsAttention = templates.find(
        template =>
          template.statusValue?.alertLevel === 'NEEDS_ATTENTION'
      )

      const groupIsExpCritical = templates.find(
        template =>
          template.statusValue?.expirationStatus === 'CRITICAL'
      )
      const groupExpNeedsAttention = templates.find(
        template =>
          template.statusValue?.expirationStatus === 'NEEDS_ATTENTION'
      )

      const groupHasOneStatus = templates.some(t => t.statusValue)

      const groupExpisReset = templates.find(
        t => t.statusValue?.expirationStatus === 'RESET'
      )
      return {
        category: templates[0].category,
        key,
        groupStatus: getGroupStatus(
          groupIsCritical,
          groupNeedsAttention,
          groupHasOneStatus
        ),
        groupStatusColor: getGroupStatusColor(
          groupIsCritical,
          groupNeedsAttention,
          false,
          groupHasOneStatus
        ),

        groupExpStatus: getExpGroupStatus(
          groupIsExpCritical,
          groupExpNeedsAttention,
          groupExpisReset,
          groupHasOneStatus
        ),
        groupExpStatusColor: getExpGroupStatusColor(
          groupIsExpCritical,
          groupExpNeedsAttention,
          groupExpisReset,
          groupHasOneStatus
        ),

        templates
      }
    })
    .sort((a, b) => {
      // undefined should always be first, then category a to z
      if (a.key === b.key) return 0
      if (a.key === 'undefined') return -1
      if (b.key === 'undefined') return 1

      return a?.category?.name.localeCompare(b?.category?.name)
    })
    .filter(g => g.templates.length > 0)
}

export function filterStatusTemplateGroups(
  statusGroups,
  filteredStatusTemplates,
  filteredStatusTemplateCategories
) {
  return statusGroups.map(sg => {
    if (
      !filteredStatusTemplateCategories &&
      !filteredStatusTemplates
    ) {
      sg.hide = false
      return sg
    }

    if (
      filteredStatusTemplateCategories &&
      filteredStatusTemplateCategories?.some(
        stc => stc.id === sg?.category?.id
      )
    ) {
      sg.hide = false
      return sg
    }

    if (filteredStatusTemplates) {
      const filteredStatuses = sg?.templates?.filter(st =>
        filteredStatusTemplates?.some(fst => fst.id === st.id)
      )

      if (filteredStatuses?.length < 1) {
        sg.hide = true
      }

      return {
        ...sg,
        templates: sg?.templates?.map(st => {
          const isFiltered =
            filteredStatuses.length > 0 &&
            filteredStatuses?.some(fst => fst.id === st.id)

          return {
            ...st,
            hide: isFiltered ? false : true
          }
        })
      }
    } else {
      sg.hide = true
      return false
    }
  })
}

export function getCurrentStatusesByDate(data, date) {
  // If date is a label, find the corresponding date object so date is relative to label
  const dateOrDateLabel =
    dateRangeOptions.find(dr => dr.label === date?.label) || date

  const { startDate, endDate } = dateOrDateLabel || {}

  return date
    ? data?.map(cs => {
        const statusValuesFilteredByDate = !date
          ? cs?.statusValues || []
          : cs?.statusValues?.filter(sv => {
              if (typeof date === 'string') {
                return moment(sv?.completedAt).isBefore(date)
              }

              if (startDate && endDate) {
                return moment(sv?.completedAt).isBetween(
                  startDate,
                  endDate
                )
              }
              return moment(sv?.completedAt).isBefore(startDate)
            }) || []

        return {
          ...cs,
          statusValues: statusValuesFilteredByDate
        }
      })
    : data
}

export function getFilteredStatusTemplates(
  statusTemplates,
  filteredStatusTemplates,
  filteredStatusTemplateCategories
) {
  let filteredTemplates = statusTemplates

  if (filteredStatusTemplates?.length > 0) {
    filteredTemplates = statusTemplates.filter(st =>
      filteredStatusTemplates.some(f => f.id === st.id)
    )
  }

  if (filteredStatusTemplateCategories?.length > 0) {
    filteredTemplates = statusTemplates.filter(st => {
      return filteredStatusTemplateCategories.some(
        f => f.id === st.category?.id
      )
    })
  }

  return filteredTemplates
}
