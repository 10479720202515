/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Badge as ThemeUIBadge, useColorMode } from 'theme-ui'
import uniqueId from 'lodash/uniqueId'
import Elipses from '../Elipses'
import ToolTip from './ToolTip'
import Icon from '../Icon'

const Badge = (
  {
    children,
    size = 'md',
    variant = 'primary',
    color = null,
    reverse,
    mw,
    withHoverTooltip,
    hoverTooltipText,
    outline,
    bg,
    icon,
    ...props
  },
  ref
) => {
  const colorMode = useColorMode()
  const randomId = uniqueId('badge_')
  // Handle size prop and map size prop to pre-sets
  if (size) {
    switch (size) {
      case 'sm':
        props.sx = {
          ...props.sx,
          fontSize: '.555rem',
          padding: !reverse && '0.15rem 0.5rem',
          lineHeight: 1,
          borderRadius: '0.2rem',
          mw
        }
        break
      case 'md':
        break
      case 'lg':
        props.sx = {
          ...props.sx,
          fontSize: '1.25rem',
          padding: !reverse && '0.5rem 1rem',
          lineHeight: 1.5,
          borderRadius: '0.3rem',
          mw
        }
        break
      default:
        break
    }
  }

  if (withHoverTooltip) {
    props.sx = {
      cursor: 'pointer'
    }
  }

  if (outline) {
    props.sx = {
      ...props.sx,
      backgroundColor: 'transparent',
      borderColor:
        colorMode === 'dark' ? 'white' : bg || `${variant}`,
      border: '1px solid',
      color: colorMode === 'dark' ? 'white' : bg || `${variant}`,
      '&:hover': {
        opacity: 0.8,
        textDecoration: 'none',
        color: colorMode === 'dark' ? 'black' : bg || `${variant}`
      }
    }
  }

  return (
    <>
      {withHoverTooltip && (
        <ToolTip placement="bottom" target={randomId}>
          {hoverTooltipText || children}
        </ToolTip>
      )}

      <ThemeUIBadge
        variant={`${variant}${reverse ? 'Reverse' : ''}`}
        data-tip
        data-for={randomId}
        bg={bg}
        {...props}
      >
        {icon && (
          <Icon icon={icon} color={'white'} width={12} height={12} />
        )}
        {mw ? <Elipses mw={mw}>{children}</Elipses> : children}
      </ThemeUIBadge>
    </>
  )
}

export default forwardRef(Badge)
