import React from 'react'

function RunningTotalIcon(props) {
  const height = props.height
  const width = props.width
  const title = props.title || 'running total'

  return (
    <svg
      height={height || '48'}
      width={width || '48'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g fill={props.color || '#f7f7f7'}>
        <path d="M7 36.99l12-12.03 8 8 17-19.12-2.82-2.83L27 26.96l-8-8L4 33.99z" />
      </g>
    </svg>
  )
}

export default RunningTotalIcon
