import { useQuery } from '@apollo/client'
import moment from 'moment'
import QueryString from 'qs'
import { GET_MY_AGENCY_REPORTS } from 'src/app/hooks/queries'
import get from 'lodash/get'

import { sevenDaysAgo, thirtyDaysAgo } from 'src/utils'

export const reportingDateRangeOptions = [
  {
    frequency: 'DAILY',
    startDate: moment()
      .startOf('day')
      .toDate(),
    endDate: moment()
      .endOf('day')
      .toDate(),

    label: 'Today'
  },
  {
    startDate: moment()
      .subtract(1, 'day')
      .startOf('day')
      .toDate(),
    endDate: moment()
      .subtract(1, 'day')
      .endOf('day')
      .toDate(),
    label: 'Yesterday'
  },
  {
    frequency: 'WEEKLY',
    startDate: sevenDaysAgo.toDate(),
    endDate: moment()
      .endOf('day')
      .toDate(),

    label: 'Last 7 days'
  },
  {
    frequency: 'MONTHLY',
    startDate: thirtyDaysAgo.toDate(),
    endDate: moment()
      .endOf('day')
      .toDate(),
    label: 'Last 30 days'
  },
  {
    startDate: moment()
      .startOf('month')
      .toDate(),
    endDate: moment()
      .endOf('month')
      .toDate(),
    label: 'This month'
  },
  {
    label: 'This quarter',
    startDate: moment()
      .startOf('quarter')
      .toDate(),
    endDate: moment()
      .endOf('quarter')
      .toDate()
  },
  {
    startDate: moment()
      .startOf('year')
      .toDate(),
    endDate: moment()
      .endOf('year')
      .toDate(),
    label: 'This year'
  },
  {
    startDate: moment()
      .subtract(1, 'month')
      .startOf('month')
      .startOf('day')
      .toDate(),
    endDate: moment()
      .subtract(1, 'month')
      .endOf('month')
      .endOf('day')
      .toDate(),
    label: 'Last month'
  },
  {
    label: 'Last quarter',
    startDate: moment()
      .subtract(1, 'quarter')
      .startOf('quarter')
      .toDate(),
    endDate: moment()
      .subtract(1, 'quarter')
      .endOf('quarter')
      .toDate()
  },
  {
    label: 'Last 6 months',
    startDate: moment()
      .subtract(6, 'months')
      .startOf('month')
      .toDate(),
    endDate: moment()
      .endOf('month')
      .toDate()
  },
  {
    startDate: moment()
      .subtract(1, 'year')
      .startOf('year')
      .toDate(),
    endDate: moment()
      .subtract(1, 'year')
      .endOf('year')
      .toDate(),

    label: 'Last year'
  },
  {
    startDate: moment()
      .subtract(3, 'year')
      .startOf('year')
      .toDate(),
    endDate: moment()
      .endOf('day')
      .toDate(),
    label: 'Last 3 years'
  }
]

export const dateRangeOptions = [
  ...reportingDateRangeOptions,

  {
    frequency: 'CUSTOM',
    startDate: thirtyDaysAgo.toDate(),
    endDate: moment()
      .endOf('day')
      .toDate(),
    label: 'Custom',
    pdfOnly: true
  }
]

export const useGetReportFromUrl = ({ skip }) => {
  const urlParams = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  })

  const savedReportId = urlParams?.reportId

  const {
    data: savedReportData,
    loading: loadingSavedReportData
  } = useQuery(GET_MY_AGENCY_REPORTS, {
    skip: skip || !savedReportId,
    variables: {
      where: {
        id: { equals: savedReportId }
      }
    }
  })

  const savedreportdetails = savedReportData?.myAgenciesReports?.[0]

  const savedReport = savedreportdetails && {
    name: savedreportdetails.name,
    id: savedreportdetails.id,
    ...get(savedreportdetails, 'options.where'),
    dateRange: dateRangeOptions.find(
      dr => dr.frequency === savedreportdetails?.frequency || 'WEEKLY'
    )
  }

  return {
    savedReport,
    loadingSavedReportData
  }
}
